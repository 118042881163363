import React from 'react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import Card from '../components/Card';
import { Link } from 'react-router-dom';

SwiperCore.use([Autoplay, Pagination, Navigation]);
export default function Home() {

    return (
        <div>
            <section>
                <div className="home-slide1 hidden md:flex lg:pl-10">
                <div className="lg:ml-10 hidden md:flex mx-auto my-auto">
                            <p className="my-auto lg:ml-10 lg:pl-10 text-gray-700 leading-normal text-center text-5xl lg:text-8xl">
                                Welcome to
                                <br />
                                Solution Seller
                            </p>
                        </div>
                        <div class='ripple-background hidden md:flex'>
                            <div class='circle xxlarge shade1'></div>
                            <div class='circle xlarge shade2'></div>
                            <div class='circle large shade3'></div>
                            <div class='circle mediun shade4'></div>
                            <div class='circle small shade5'></div>
                        </div>
                    <div className="home-mob md:hidden">
                       

                        
                        <div className="lg:ml-10 mx-auto flex md:hidden mt-auto mb-32">
                            <p className="my-auto lg:ml-10 lg:pl-10 text-gray-700 leading-normal text-center text-5xl lg:text-8xl">
                                Welcome to
                                <br />
                                Solution Seller
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-10 back ">
                <div className="justify-center flex">
                    <div className="text-7xl text-gray-700 w-100 mx-auto mb-10 py-10">
                        Latest Posts
                    </div>
                </div>
                <br />
                <div class="grid lg:grid-cols-3 grid-cols-1 mt-10 px-10 items-center gap-6">
                    <div >
                        <Card >
                            <Link to={{ pathname: "https://www.facebook.com/solutionseller" }} target="_blank" >
                                <img className="w-28" src="imgs/fb.png" alt="digital-marketing-agency-facebook-page" />
                            </Link>
                        </Card>
                    </div>

                    <div>
                        <Card>
                            <Link to={{ pathname: "https://www.instagram.com/solutionseller_official/" }} target="_blank" >
                                <img className="w-32 my-5" src="imgs/insta.png" alt="digital-marketing-agency-instagram-page" />
                            </Link>
                        </Card>
                    </div>
                    <div>
                        <Card>
                            <Link to={{ pathname: "https://www.linkedin.com/company/solution-seller" }} target="_blank" >
                                <img className="w-32 my-5" src="imgs/in.png" alt="digital-marketing-agency-linkedin-page" />
                            </Link>
                        </Card>
                    </div>
                    <div>

                    </div>
                    <div>
                    </div>
                </div>
                <div className="mx-auto max-w-xl lg:max-w-2xl">
                    <Card>
                        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/tRxoxWGo99g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Card>
                </div>
            </section >
        </div >
    )
}
