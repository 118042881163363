import React from 'react'

export default function Clientele() {
    return (
        <div>

            <div className="clientele hidden md:block ">
                <div class='ripple-background h'>
                    <div class='circle xxlarge shade1'></div>
                    <div class='circle xlarge shade2'></div>
                    <div class='circle large shade3'></div>
                    <div class='circle mediun shade4'></div>
                    <div class='circle small shade5'></div>
                </div>
            </div>
            <div className="clientelem md:hidden">

            </div>
            <div className="back">
                <section className="p-10 grid xl:grid-cols-3 grid-cols-1 px-10 max-w-7xl mx-auto items-center gap-10">
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo1.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo2.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/fruitfacelogo.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/wokfacelogo.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo4.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo5.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo6.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo7.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo8.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo9.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo10.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo11.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo12.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/logo13.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/sculpt.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/Socials_logo.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/offlimits.png" alt="" />
                    </div>
                    <div className="shadow-xl rounded-full bg-gray-100 shadow-2xl flex  justify-center items-center p-2">
                        <img src="imgs/tallblondefrench.png" alt="" />
                    </div>

                </section>
            </div>
        </div>
    )
}
