import React from 'react'
import Footer from './footer'
import Navbarr from './navbarr'



export default function Layout({ children }) {

    return (
        <div className="bg-gray-600 bg-opacity-20">
            <div className="bakk">
                <Navbarr />
                <div className="p-10 ">
                    <div className="p-2">
                    </div>
                </div>
                <div>
                    {children}
                </div>
                <Footer />
            </div>
        </div>
    )
}
