import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from './components/layout';
import Scroll from './components/scroll';
import About from './pages/about';
import Baas from './pages/baas';
import Clientele from './pages/clientele';
import Contact from './pages/contact';
import Home from './pages/home';
import Join from './pages/join';
import Maas from './pages/maas';
import Privacy from './pages/privacypolicy';
import Saas from './pages/saas';
import Story from './pages/story';
import Taas from './pages/taas';
import Terms from './pages/terms';
import Refunds from './pages/refunds';


function App() {
  return (
    <Router>
      <Scroll/>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/maas">
            <Maas />
          </Route>
          <Route path="/baas">
            <Baas />
          </Route>
          <Route path="/taas">
            <Taas />
          </Route>
          <Route path="/saas">
            <Saas />
          </Route>
          <Route path="/story">
            <Story />
          </Route>
          <Route path="/career">
            <Join />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/clientele">
            <Clientele/>
          </Route>
          <Route path="/privacypolicy">
          <Privacy/>
          </Route>
          <Route path="/terms">
          <Terms/>
          </Route>
          <Route path="/refunds">
          <Refunds/>
          </Route>
        {/* </Route> */}
          <Route path='/intelltech' component={() => {
            window.location.href = 'https://intelltech.in/';
            return null;
          }} />
        </Switch>
      </Layout>

    </Router>
  );
}

export default App;