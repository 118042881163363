import React from 'react'
import { Link } from 'react-router-dom'

export default function terms() {
    return (
        <div className="text-justify max-w-5xl mx-auto">
            <h1 className="text-4xl text-center font-bold p-10">Terms & Conditions</h1>

            <p>The General Terms & Conditions (“GCTCs”) as mentioned below shall form part and parcel of the overleaf form filled (“Form”) by you and shall collectively be referred herein as Agreement between  Solution Seller (A unit of Business Brigade Solutions) https://www.SolutionSeller.in/
401,4th Floor, 139/Sterling Heights, Infantry Road, Shivajinagar 560001 (hereinafter referred to as the“Consultant”,which term shall
unless excluded by or repugnant to the context or meaning thereof, shall include its successors and permitted assigns) AND You the above said registered “Investors/Brand/Client First” which term shall unless excluded by or
repugnant to the context or meaning thereof, shall include your successors and permitted assigns. We shall collectively be referred to as “Parties” and individually as “Party”.
1.	Unless terminated earlier, this Agreement shall come into effect on date of signing (“Effective Date”) and shall remain valid for a period of 01year from the Effective Date, or until the Investors/Brand/Client First has selected and
then finalised the respective /investment opportunity, whichever is earlier (“Term”).
2.	The Consultant shall be providing consulting services to you the Investors/Brand/Client First in relation to the  opportunities as desired by you the Investors/Brand/Client First in the overleaf Form, so as to enable the Investors/Brand/Client First to identify and select an appropriate /investment opportunity as a Solution Seller (A unit of Business Brigade Solutions)e// associate/Investors/Brand/Client First. The Investors/Brand/Client First shall share all details and information such as its preferential investment sectors, time period and plans for making investment, investment amount, source of funds etc. for the proposed investment with the Consultant, so as to enable the Consultant to identify an appropriate /investment opportunity for the Investors/Brand/Client First. The Investors/Brand/Client First acknowledges that in providing its services hereunder, the Consultant will be solely relying on the information provided by the Investors/Brand/Client First. The Consultant shall be under no obligation to verify any information provided to it by the Investors/Brand/Client First and shall not be liable for the accuracy and completeness of information furnished to it by the Investors/Brand/Client First. The Investors/Brand/Client First agrees and acknowledges that upon successful sign up of the agreement between the lead/brand and the Investors/Brand/Client First, the Consultant shall not be held liable for any claim or demands arising due to the  relationship or dispute between such signed lead/brand and the Investors/Brand/Client First.
3.	Upon receipt of information from the Investors/Brand/Client First, the Consultant shall assist the Investors/Brand/Client First in exploring new /investment opportunities, available in the market, by providing service to the Investors/Brand/Client First as morefully detailed in Exhibit-A annexed herewith. (“Services”)
4.	It is understood by the Investors/Brand/Client that the services offered herein are on “best efforts” basis and for the closure of deal active participation of Investors/Brand/Client is must. The consultant endeavoured to put in its best efforts, however does not give any commitment for closure of the deal.
5.	The Investors/Brand/Client First shall promptly inform the Consultant of all the leads converted/ materialised during the term of the agreement as well as post termination of this Agreement, which are/were materialised as result
of the Consultant's efforts during the Term of the Agreement, and the Investors/Brand/Client First acknowledges that the Consultant is bound to be paid by the Investors/Brand/Client First for such leads.
6.	The Consultant shall provide the services under this Agreement with promptness and diligence and at a level of proficiency usually expected from a consultant with the background and experience that the Consultant has represented it has. However, the Parties acknowledge that the Consultant cannot and does not warrant the success of the transaction contemplated hereunder and further the Consultant does not guarantee that the actual results of the services rendered, will be similar to any of the projections or forecasts provided by the Consultant under this Agreement.
7.	The Investors/Brand/Client has agreed that it will not approach any of the Solution Seller (A unit of Business Brigade Solutions) companies or company; whose information is shared/ provided by the Consultant and all correspondence, negotiation, execution of any agreements etc. will be done through Consultant only. The Investors/Brand/Client is obligated to inform the Consultant of any form of communication whether in written form / oral or electronic means, between the Investors/Brand/Client and the Solution Seller (A unit of Business Brigade Solutions) companies or company which have been referred by the Consultant.
8.  In consideration of the consulting services to be rendered by virtue of this Agreement, the Investors/Brand/Client First shall make payments respectively
9.	The Investors/Brand/Client First shall not offer to hire or hire any person who is currently, or was within a period of six months of such hiring, employed by the Consultant with whom the Investors/Brand/Client First had contact during the tenure of this Agreement. Further, the Investors/Brand/Client First covenants that the Agreement shall be exclusive in nature and that during the Term of this Agreement the Investors/Brand/Client First shall refrain from hiring third party for similar Services as those being rendered by the Consultant under this Agreement. Nothing contained in this Agreement shall prevent the Consultant from entering into similar agreements with any third party, for providing services similar to the Services envisaged under this Agreement.
10. The Investors/Brand/Client First makes the following representations and warranties to the Consultant:
(a)  it has the power to enter into this Agreement and comply with its obligations under it;
(b)  it has in full force and effect the authorizations necessary for it to enter into this Agreement and the transactions contemplated under it;
(c)  The information provided to the Consultant do not contain any untrue statements or facts or omit to state any material fact that is required to be stated or intimated to the Consultant; and
(d)  It has requisite financial stability and back up to carry out the investment/ opportunity selected by it from the options proposed by the Consultant and such funds have been obtained in compliance with applicable law.
11. Both the Parties shall have the right to terminate this Agreement at any time for its convenience by giving 30 (Thirty) days’ prior written notice to the other Party. The Consultant shall have the right to terminate this
Agreement by giving a prior notice of 15 (Fifteen) days’, upon happening of the following events:
(a)  If the Investors/Brand/Client First is in breach of its payment obligations under this Agreement;
(b)  If the Consultant finds that the Investors/Brand/Client First has furnished incorrect information to the Consultant; or
(c)   If any of the representations and warranties given by the Investors/Brand/Client First under this Agreement are violated or false;
(d)  Where the Investors/Brand/Client First is a Company or a Partnership Firm, if any of the Directors of the Investors/Brand/Client First’s company or if any of the Partners of the Investors/Brand/Client First’s firm, has subsequently turned unsound
mind or otherwise incompetent to Agreement. In case the Investors/Brand/Client First is a proprietorship concern, the same would apply to the proprietor.
12. Upon termination of this Agreement:
(a)  The Investors/Brand/Client First shall make payments of all outstanding dues to the Consultant within 7 days from the date of termination;
(b)  Each Party shall deliver to the other Party all property or confidential information in its possession and exchanged between them pursuant to this Agreement.
(c)   The Investors/Brand/Client First shall comply with its payment obligations with respect to the leads that are concluded post termination by virtue of this Agreement.
13. Each Party shall maintain confidentiality of the terms of this Agreement and the information received by virtue of the same. Notwithstanding the foregoing, nothing in this Agreement shall prevent either party from making any disclosure of Confidential Information required by law.
14. The Investors/Brand/Client First undertakes and agrees that it shall not circumvent the role of the Consultant at any-time with respect to the transaction contemplated under this Agreement or in relation to the Services being provided by the Consultant, whether by way of side agreement or otherwise. Further, the Investors/Brand/Client First shall ensure that any person, entity, company connected with it, formally or informally, does not cause or bring about circumvention as above, by proceeding directly at any stage during the Term of this Agreement thereby, bypassing and directly approaching any third party. The Investors/Brand/Client First acknowledges and agrees that during the Term of this Agreement, if the Investors/Brand/Client First receives any  offer or proposal from any third party then it shall provide the details of the same to the Consultant and shall always proceed through the Consultant. The Parties agree that upon expiry of the Term or earlier termination thereof, the Investors/Brand/Client First shall be bound to pay to the Consultant, the Success Fee, pursuant to Section 5 above, if any, within 7 days to the Consultant, arising on account of conversion of the potential /investment opportunities into a lead, wherein, such opportunity was introduced to the Investors/Brand/Client First by the Consultant, during the Term of the Agreement.
15. The Investors/Brand/Client First acknowledges and agrees that the Consultant’s observation and recommendations pursuant to this Agreement are purely advisory in nature and shall be restricted for Investors/Brand/Client First’s use only.
Thus, the Investors/Brand/Client First must undertake its own evaluation and due diligence qua the potential /investment opportunity proposed by the Consultant and seek its own fiscal, legal or financial guidance. 16.This Agreement shall be governed and interpreted in accordance with the laws of India.All disputes between the parties shall be subject to jurisdiction of the Courts at Bangalore only.All disputes arising between
the Parties shall be settled as per the provisions of Arbitration and Conciliation Act, 1996, by the sole arbitrator to be appointed by the Consultant. The award of the sole arbitrator shall be final and binding upon
the Parties. Arbitration shall be conducted in the English language and the seat of arbitration shall be Bengaluru.
17. Investors/Brand/Client First shall protect, indemnify and save harmless the Consultant from and against any and all damages, claims, suits, actions, judgments, costs and expenses whatsoever (including reasonable legal fees) which may be suffered or incurred (a) as a result of breach of the obligations by the Investors/Brand/Client First under this Agreement, (b) as a result of the representations or warranties made by the Investors/Brand/Client First found to be untrue, false or misleading, (c) as a result of gross negligence or willful misconduct of the Investors/Brand/Client First, (d) by third party for loss or damage to property or personal injury or death of such third party, resulting from, or incidental to Investors/Brand/Client First’s performance under this Agreement.
18. Neither Party shall be liable to the other for any indirect, incidental, punitive, special or consequential loss, damage, cost or expense including, without limitation, loss of profits, loss of data, and loss of revenues, of any kind whatsoever and however caused, whether arising under contract, tort (including negligence or breach of statutory duty) or otherwise, even if that Party has been advised of its possibility.
19. The failure at any time of either Party to demand strict performance by the other of any of the terms, covenants or conditions set forth herein shall not be construed as a continuing waiver or relinquishment thereof, and either Party may, at any time, demand strict and complete performance by the other of such terms, covenants and conditions.
20. The Investors/Brand/Client First shall have no right to assign or transfer any of its rights or obligations hereunder without the express prior written consent of the Consultant. Provided however, the Consultant shall be entitled to freely assign or transfer its rights or obligations pursuant to this Agreement with prior intimation to the Investors/Brand/Client First.
21. The invalidity or unenforceability of any provision in this Agreement shall in no way affect the validity or enforceability of any other provision herein. In the event of the invalidity or unenforceability of any provision of this Agreement, the Parties will immediately negotiate in good faith to replace such a provision with another, which is not prohibited or unenforceable and has, as far as possible, the same legal and commercial effect as that which it replaces.
22. Neither Party shall be liable for any loss to the other Party caused by the failure to observe the terms and conditions of this Agreement, wherein such failure is occasioned by any cause beyond the other Party’s reasonable control such as act of God, war, insurrection, riot, fire, flood, epidemic, earthquake, strikes, lock-outs, labor controversy, civil commotion, act of terrorism, any change/repudiation of law, statute, act, rules, regulations, policies, bye-laws or similar cause. The Party having any such cause shall promptly notify the other Party in writing of the nature of such cause and the expected delay.
23. This Agreement constitutes the entire agreement between the Parties with respect to the subject matter of Agreement and supersedes all communications, negotiations and agreements (whether written or oral) of Parties with respect thereto made prior to the date of this Agreement.
24. No amendment or other variation of this Agreement shall be effective unless it is in writing, is dated, expressly refers to this Agreement, and is signed by a duly authorised representative of each Party.
25. It is agreed between the Parties that the relationship of Consultant with the Investors/Brand/Client First is that of an independent contractor and that the obligations and responsibilities of the Consultant to the Investors/Brand/Client First are limited to those specifically set forth herein.
26. Nothing in this Agreement shall be deemed to create any agency, partnership, joint venture unincorporated association, co-operative entity or other joint relationship between the Parties hereto or constitute any Party the agent of the other Party for any purpose or entitle any Party to commit or bind the other Party in any manner or give rise to fiduciary duties by one Party in favour of the other Party.
27. The Consultant shall have the right to subcontract any part of its scope of work or Services required to be rendered by it under this Agreement to any third party. IN WITNESS WHEREOF the Parties hereto have set and subscribed their respective hands and seals on the day month and year first written above.
</p>
</div>
    )
}

