import React, { useState } from 'react'
import { Link } from 'react-router-dom'


export default function Navbarr() {
    window.onload = function () {
        const btn = document.querySelector(".ham");
        const menu = document.querySelector(".menu");

        btn.addEventListener('click', () => {
            menu.classList.toggle("hidden")
        })
    }
    const [navbar, setNavbar] = useState(false)

    const navbg = () => {
        if (window.scrollY >= 100) {
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', navbg)

    return (
        <div>
            <nav className={navbar ? ' navbarr bg-gray-100 z-40 shadow-xl' : ' z-40 navbarr '}>
                <div className="bg-white lg:bg-transparent stickerr w-100 text-xl text-gray-600">
                    <div className="max-w-7xl px-5 py-5 mx-auto">
                        <div className="flex justify-around">
                            <Link to="/">
                                <div className="flex items-center">
                                    <img width="120" src="imgs/logo.png" alt="digital-marketing-agency-logo" />
                                    <div className="bold pl-4 text-yellow-600">
                                        Solution Seller
                                    </div>
                                </div>
                            </Link>
                            <div className="flex hidden lg:flex items-center space-x-7">
                                <Link className="hover:text-black" to="/about">
                                    Our Services
                                </Link>

                                <Link className="hover:text-black" to="/clientele">
                                    Our Clientele
                                </Link>

                                <Link className="hover:text-black" to="/contact">
                                    Contact Us
                                </Link>
                            </div>
                            <div className="lg:hidden ham flex items-center">
                                <button>
                                    <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>

                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="lg:hidden hidden menu items-center justify-right px-4 space-y-5 py-5">
                        <Link className="hover:text-black block " to="/about">
                            Our Services
                        </Link>

                        <Link className="hover:text-black block " to="/clientele">
                            Our Clientele
                        </Link>

                        <Link className="hover:text-black block " to="/contact">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </nav>
        </div>
    )
}

